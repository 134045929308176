import React from 'react'
import Layout from '../components/layout/Layout/Layout'
import { graphql } from 'gatsby'
import HeroBanner from '../components/HeroBanner/HeroBanner'
import Details from '../components/Details/Details'

// Todo Add listing data for content types other than Pages
export const query = graphql`
  query ($slug: String!) {
    datoCmsJourney(slug: {eq: $slug}) {
      id
      slug
      title
      introductoryText
      fallbackMapImage: imageUsedForListingPages {
        alt
        title
        fluid(maxWidth: 660, imgixParams: { fm: "jpg", auto: "compress", fit: "crop", w: "660", h: "560" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      fallbackMapImageMobile: imageUsedForListingPages {
        fluid(maxWidth: 660, imgixParams: { fm: "jpg", auto: "compress", fit: "crop", w: "660", h: "560" }) {
          ...GatsbyDatoCmsFluid
        }
        title
        alt
      }
      journeyFeatures {
        name
        description
        icon {
          alt
          fixed(width: 30, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFixed
          }
          title
        }
      }
      journeyCategory {
        name
      }
      steps {
        title
        location {
          longitude
          latitude
        }
      }
      route
      startLocation
      endLocation
      distance
      difficulty
      estimatedTime
      pointsOfInterest
      seo {
        image {
          url
        }
        description
        title
        twitterCard
      }
    }
  }
`

const ActivityDetail = (props) => {
  const dataJourney = props.data.datoCmsJourney;
  // Get separate images based on breakpoint.
  // Then pass this down to eventually reach the Map component.
  let imageSources = [];
  if (dataJourney.fallbackMapImage) {
    imageSources = [
      dataJourney.fallbackMapImageMobile.fluid,
      {
        ...dataJourney.fallbackMapImage.fluid,
        media: `(min-width: 768px)`,
      },
    ]
  }

  const markers = []
  props.data.datoCmsJourney.steps.map((step) => {
    return (
      markers.push({
        lat: step.location.latitude,
        lng: step.location.longitude,
        description: step.title,
      })
    )
  })

  // Get separate images based on breakpoint.
  // Then pass this down to eventually reach the Map component.
  return (
    <Layout
      metaTitle={props.data.datoCmsJourney.seo ? props.data.datoCmsJourney.seo.title : props.data.datoCmsJourney.title}
      metaDescription={props.data.datoCmsJourney.seo ? props.data.datoCmsJourney.seo.description : null}
      metaImage={
        props.data.datoCmsJourney.seo && props.data.datoCmsJourney.seo.image ? props.data.datoCmsJourney.seo.image.url : null
      }
      twitterCard={
        props.data.datoCmsJourney.seo && props.data.datoCmsJourney.seo.twitterCard
          ? props.data.datoCmsJourney.seo.twitterCard
          : null
      }
      pathname={props.uri}
    >
      <HeroBanner />
      <Details
        leftOrRight='right'
        isBeach={false}
        title={dataJourney.title}
        distance={dataJourney.distance}
        POI={dataJourney.pointsOfInterest}
        difficulty={dataJourney.difficulty}
        features={dataJourney.journeyFeatures}
        description={dataJourney.introductoryText}
        location={dataJourney.startLocation}
        imageSrc={imageSources}
        journeyCategory={dataJourney.journeyCategory.name}
        breadcrumbUrl={props.uri}
      />
    </Layout>
  )
}

export default ActivityDetail
